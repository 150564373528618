export const drawRect = (detections, ctx) => {
    // Loop through each prediction
    let previousPrediction = "";
    detections.forEach((prediction) => {
      // Extract boxes and classes
      const [x, y, width, height] = prediction["bbox"];
      const text = prediction["class"];
  
      // speak(text);
  
      // Set styling
      const color = Math.floor(Math.random() * 16777215).toString(16);
      ctx.strokeStyle = "#" + color;
      ctx.font = "18px Arial";
  
      // Draw rectangles and text
      ctx.beginPath();
      ctx.fillStyle = "#" + color;
      ctx.fillText(text, x, y);
      ctx.rect(x, y, width, height);
      ctx.stroke();
  
    });
  };

export const speak = (text) => {
    // Create a SpeechSynthesisUtterance
    const utterance = new SpeechSynthesisUtterance(text);
  
    // Select a voice
    const voices = speechSynthesis.getVoices();
    utterance.voice = voices[0]; // Choose a specific voice
  
    // Speak the text
    speechSynthesis.speak(utterance);
  }
  